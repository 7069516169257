import Axios from "axios";
import Woonobject, { IWoonobject, WoonobjectDTO } from "@/models/Woonobject";

export default abstract class WoonobjectService {
  private static axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
  });

  public static async list(): Promise<Woonobject[]> {
    const response = await this.axios.get<WoonobjectDTO[]>("/woonobjects");
    return response.data.map((dto: WoonobjectDTO) => new Woonobject(dto));
  }

  public static async get(slug: string): Promise<Woonobject> {
    const response = await this.axios.get<WoonobjectDTO>(
      "/woonobjects/" + slug
    );
    return new Woonobject(response.data);
  }

  public static async store(
    woonobject: Partial<IWoonobject>
  ): Promise<Woonobject> {
    const response = await this.axios.post("/woonobjects", woonobject);
    return response.data;
  }

  public static async update(woonobject: Woonobject): Promise<void> {
    const response = await this.axios.put(
      "/woonobjects/" + woonobject.slug,
      woonobject
    );
    return response.data;
  }

  public static async delete(slug: string): Promise<boolean> {
    const response = await this.axios.delete("/woonobjects/" + slug);
    return response.status === 204 ? true : false;
  }
}
