










































import { Component, Prop, Vue } from "vue-property-decorator";

type VForm = Vue & { validate: () => boolean };

@Component
export default class DeleteItemFormDialog extends Vue {
  @Prop({ required: true }) private readonly type!: string;
  @Prop({ required: true }) private readonly item!: string;
  @Prop({ type: Boolean }) private readonly needVerification!: boolean;

  private dialog = false;
  private verification = "";

  public close(): void {
    this.dialog = false;
  }

  private submit(): void {
    if (this.needVerification) {
      if ((this.$refs.form as VForm).validate()) {
        this.$emit("accept", this.item);
      }
    } else {
      this.$emit("accept", this.item);
    }
  }
}
