






























































import { Component, Vue } from "vue-property-decorator";
import FileUploadField from "@/components/FileUploadField.vue";
import { ImageModel } from "@/models/ImageModel";
import ImageService from "@/services/ImageService";
enum State {
  IDLE = 0,
  BUSY = 1,
  COMPLETE = 2,
  ERROR = 3,
}
@Component({ components: { FileUploadField } })
export default class FileUploadFormDialog extends Vue {
  private state = State.IDLE;
  private dialog = false;
  private files: ImageModel[] = [];
  private errorMessage = "";
  /**
   * Getter for getting the token from the store.
   */
  protected get token(): string {
    return this.$store.getters["auth/getToken"];
  }
  /**
   * Eventhandler for selecting a file
   * Uploads the file to the server and then emits an 'input' event with the file data
   * @param {File[]} e Event data
   */
  private async onFileSelect(e: File[]): Promise<void> {
    this.state = State.BUSY;

    let form = new FormData();
    form.append("wng_slug", this.$route.params.slug);

    for (const file of e) {
      form.append("files[]", file);
    }

    try {
      const result = await ImageService.store(form);

      this.$emit("update", new ImageModel(result));
      this.state = State.COMPLETE;
    } catch (e: any) {
      this.state = State.ERROR;
      this.errorMessage = e.response.statusText;
    }
  }
  /**
   * Clears the state and files of the dialog
   */
  public resetForm(): void {
    this.state = State.IDLE;
    this.files = [];
  }
  /**
   * Resets and closes the form
   */
  public closeForm(): void {
    this.resetForm();
    this.dialog = false;
  }
}
