









































































































import axios from "axios";
import { Component, Emit, Vue } from "vue-property-decorator";
import Validation from "@/models/Validation";
import { IWoonobject } from "@/models/Woonobject";

type VForm = Vue & { validate: () => boolean };

@Component
export default class NewWoonobjectFormDialog extends Vue {
  public dialog = false;
  private editAddress = false;
  private loadingAddress = false;
  private editSlug = false;
  private readonly Validation = Validation;
  private woonObject: Partial<IWoonobject> = {
    straat: "",
    huisnummer: "",
    postcode: "",
    plaats: "",
    slug: "",
  };

  get slug(): string {
    if (this.editSlug) {
      return this.woonObject.slug?.toLowerCase() ?? "";
    } else {
      return `${this.woonObject.plaats?.replaceAll(" ", "-")}-${
        this.woonObject.straat
      }-${this.woonObject.huisnummer}`
        .toLowerCase()
        .replaceAll(".", "");
    }
  }

  @Emit("submit")
  public submit(): Partial<IWoonobject> | false {
    if ((this.$refs.form as VForm).validate()) {
      this.woonObject.slug = this.slug;
      return this.woonObject;
    }
    return false;
  }

  private fetchAddress() {
    if (
      (this.$refs.postcodeField as VForm).validate() &&
      (this.$refs.huisnummerField as VForm).validate()
    ) {
      this.loadingAddress = true;
      axios.get(`${process.env.VUE_APP_API_URL}/postcodeapikey`).then((res) => {
        const key = res.data.api_key;
        axios
          .get(
            `https://postcode.tech/api/v1/postcode?postcode=${this.woonObject.postcode}&number=${this.woonObject.huisnummer}`,
            { headers: { Authorization: `Bearer ${key}` } }
          )
          .then((res) => {
            this.woonObject.plaats = res.data.city;
            this.woonObject.straat = res.data.street;
            this.loadingAddress = false;
          });
      });
    }
  }

  public close(): void {
    this.dialog = false;
  }
}
