























import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class FileUploadField extends Vue {
  @Prop() private readonly height!: string;
  @Prop() private readonly accept!: string;
  @Prop() private readonly multiple!: boolean;
  private dragging = false;
  onChange(e: Event & { target: HTMLInputElement }): void {
    this.dragging = false;
    if (!e.target.files || e.target.files.length === 0) return;
    let files: File[] = [];
    for (const file of e.target.files) {
      files.push(file);
    }
    this.$emit("input", files);
  }
}
