export interface IImageModel {
  id?: number;
  path: string;
  alt: string;
  priority: number;
}

export class ImageModelDTO implements IImageModel {
  id?: number;
  path = "";
  alt = "";
  priority = -1;
}

export class ImageModel extends ImageModelDTO {
  constructor(imageModel: ImageModelDTO) {
    super();
    Object.assign(this, imageModel);
  }

  get src(): string {
    return `${process.env.VUE_APP_CDN_URL}/public/images/verkoop/${this.path}`;
  }
}
