









































































































































import ComponentState from "@/models/Enum/ComponentState";
import PrijsType from "@/models/Enum/PrijsType";
import ObjectState from "@/models/Enum/ObjectState";
import Woonobject from "@/models/Woonobject";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AxiosError } from "axios";
import { Component, Watch } from "vue-property-decorator";
import MainShell from "../MainShell.vue";
import ImageListCard from "@/components/ImageListCard.vue";
import WoonobjectService from "@/services/WoonobjectService";
import ImageService from "@/services/ImageService";
import { ImageModel } from "@/models/ImageModel";

interface VSelectItems {
  text: string | number | unknown;
  value: string | number | unknown;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

enum EditedAttributes {
  OBJECT_DATA = 0,
  OBJECT_IMAGES = 1,
}

@Component({
  components: { ckeditor: CKEditor.component, ImageListCard },
})
export default class ObjectEditView extends MainShell {
  private obj: Partial<Woonobject> = {};
  private _changes!: Set<EditedAttributes>;
  public editor = ClassicEditor;

  // eslint-disable-next-line
  public editorConfig: any = {
    mediaEmbed: {
      previewsInData: true,
    },
  };

  public isChanged(): boolean {
    return this._changes.size > 0;
  }

  async mounted(): Promise<void> {
    this.obj = await WoonobjectService.get(this.$route.params.slug);
    this._changes = new Set<EditedAttributes>();
    this.$nextTick(() => {
      this.state = ComponentState.READY;
    });
  }

  private prijstypeOptions: VSelectItems[] = [
    {
      text: "Kosten Koper",
      value: PrijsType.KOSTEN_KOPER,
    },
    {
      text: "Vrij op naam",
      value: PrijsType.VRIJ_OP_NAAM,
    },
    {
      text: "BTW",
      value: PrijsType.BTW,
    },
  ];

  private objectStates: VSelectItems[] = [
    {
      text: "Te koop",
      value: ObjectState.TEKOOP,
    },
    {
      text: "In overleg",
      value: ObjectState.OVERLEG,
    },
    {
      text: "Verkocht onder voorbehoud",
      value: ObjectState.VOORBEHOUD,
    },
    {
      text: "Verkocht",
      value: ObjectState.VERKOCHT,
    },
  ];

  @Watch("obj.kort", { immediate: false, deep: false })
  @Watch("obj.beschrijving", { immediate: false, deep: false })
  @Watch("obj.vraagprijs", { immediate: false, deep: false })
  @Watch("obj.prijstype", { immediate: false, deep: false })
  @Watch("obj.status", { immediate: false, deep: false })
  @Watch("obj.showOnAanbod", { immediate: false, deep: false })
  // eslint-disable-next-line
  onObjChange(val: Woonobject, oldVal: Woonobject): void {
    if (this.state === ComponentState.READY) {
      this._changes.add(EditedAttributes.OBJECT_DATA);
      console.log("objchange");
    }
  }

  @Watch("obj.images", { immediate: false, deep: true })
  // eslint-disable-next-line
  onObjImagesChange(val: Woonobject, oldVal: Woonobject): void {
    if (this.state === ComponentState.READY) {
      this._changes.add(EditedAttributes.OBJECT_IMAGES);
      console.log("imgchange");
    }
  }

  async update(): Promise<void> {
    this.state = ComponentState.BUSY;
    try {
      if (this._changes.has(EditedAttributes.OBJECT_DATA))
        await WoonobjectService.update(this.obj as Woonobject);

      if (this._changes.has(EditedAttributes.OBJECT_IMAGES))
        if (this.obj.slug && this.obj.images)
          await ImageService.update(
            this.obj.images as ImageModel[],
            this.obj.slug
          );

      this.$toast.success("Wijzigingen Opgeslagen!");
      this.state = ComponentState.READY;
      this._changes.clear();
    } catch (error) {
      this.$toast.error(
        `Er is iets fout gegaan. (Code ${
          (error as AxiosError).response?.status
        })`
      );
      this.state = ComponentState.READY;
    }
  }
}
