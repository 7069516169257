var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.value},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: 'ObjectEditView', params: { slug: item.slug } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('delete-item-form-dialog',{ref:"deleteDialog",attrs:{"need-verification":"","item":item.slug,"type":"woning"},on:{"accept":_vm.remove},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getStatus())+" ")]}}],null,true)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})}
var staticRenderFns = []

export { render, staticRenderFns }