


























































import { Component, Prop, Vue } from "vue-property-decorator";

import DeleteItemFormDialog from "@/components/DeleteItemFormDialog.vue";
import ImageUploadFormDialog from "@/components/FileUploadFormDialog.vue";

import { ImageModel } from "@/models/ImageModel";
import isValidHttpUrl from "@/helpers/ImageSourceHelper";
// eslint-disable-next-line
import draggable from "vuedraggable";

@Component({
  components: { draggable, DeleteItemFormDialog, ImageUploadFormDialog },
})
export default class ImageListCard extends Vue {
  @Prop({ required: true }) private readonly value!: ImageModel[];

  get images(): ImageModel[] {
    return this.value;
  }

  set images(value: ImageModel[]) {
    this.$emit("input", value);
  }

  async deleteImage(e: string): Promise<void> {
    try {
      const index = this.images.findIndex((i) => i.path === e);
      this.images.splice(index, 1);
      // console.log(e);
      // if (this.images[index].id) {
      //   await ImageService.delete(String(this.images[index].id));
      //   this.$toast.success("Foto verwijderd!");
      // }
    } catch (e: any) {
      console.error(e);
      this.$toast.error(
        "Er is iets fout gegaan bij het verwijderen van de foto"
      );
    }
  }

  private onFileUploadFormDialog_Update(newImage: ImageModel): void {
    this.images.unshift(newImage);
  }

  private getSrcURL(input: string): string {
    if (isValidHttpUrl(input)) {
      return input;
    } else {
      return process.env.VUE_APP_CDN_URL + "/images/verkoop/" + input;
    }
  }
}
