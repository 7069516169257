


























import NewWoonobjectFormDialog from "@/components/NewWoonobjectFormDialog.vue";
import ObjectList from "@/components/ObjectList.vue";
import Woonobject, { IWoonobject } from "@/models/Woonobject";
import { Component } from "vue-property-decorator";
import MainShell from "../MainShell.vue";

// Services
import WoonobjectService from "@/services/WoonobjectService";
import ComponentState from "@/models/Enum/ComponentState";

@Component({
  components: {
    ObjectList,
    NewWoonobjectFormDialog,
  },
})
export default class ObjectListView extends MainShell {
  private woonObjects: Woonobject[] = [];

  async mounted(): Promise<void> {
    try {
      this.woonObjects = await WoonobjectService.list();
      this.state = ComponentState.READY;
    } catch (ex) {
      this.$toast.error("Er is iets fout gegaan: " + ex);
    }
  }

  protected async onNewWoonObjectFormDialog_Submit(
    woonObject: Partial<IWoonobject>
  ): Promise<void> {
    try {
      const result = await WoonobjectService.store(woonObject);

      this.$toast.success(result.slug + "succesvol aangemaakt!");
      this.$router.push({
        name: "ObjectEditView",
        params: { slug: result.slug },
      });
    } catch (error) {
      this.$toast.error("Er is iets fout gegaan");
    }
  }
}
