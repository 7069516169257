export default abstract class Validation {
  public static required = (v: string): boolean | string =>
    !!v || "Verplicht veld!";
  public static email = (v: string): boolean | string =>
    /.+@.+\..+/.test(v) || "Vul een emailadres in!";
  public static password = (v: string): boolean | string =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/.test(
      v
    ) || "Ongeldig wachtwoord!";
  public static postcode = (v: string): boolean | string =>
    /^[1-9][0-9]{3}?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || "Ongeldige postcode!";
  public static nospace = (v: string): boolean | string =>
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/i.test(v) || "Geen spaties toegestaan!";
}
