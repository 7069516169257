import Axios from "axios";
import { ImageModel, IImageModel, ImageModelDTO } from "@/models/ImageModel";

export default abstract class WoonobjectService {
  private static axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
  });

  public static async list(): Promise<ImageModel[]> {
    const response = await this.axios.get<ImageModelDTO[]>("/images");
    return response.data.map((dto: ImageModelDTO) => new ImageModel(dto));
  }

  public static async get(slug: string): Promise<ImageModel[]> {
    const response = await this.axios.get<ImageModelDTO[]>("/images/" + slug);
    return response.data.map((dto: ImageModelDTO) => new ImageModel(dto));
  }

  public static async store(formdata: FormData): Promise<ImageModel> {
    const response = await this.axios.post("/images", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return new ImageModel(response.data[0] as IImageModel);
  }

  public static async update(image: ImageModel[], slug: string): Promise<void> {
    const response = await this.axios.put("/images/" + slug, image);
    return response.data;
  }

  public static async delete(slug: string): Promise<boolean> {
    const response = await this.axios.delete("/images/" + slug);
    return response.status === 204 ? true : false;
  }
}
