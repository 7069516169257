import ObjectState from "./Enum/ObjectState";
import PrijsType from "./Enum/PrijsType";
import { IImageModel, ImageModel, ImageModelDTO } from "./ImageModel";

export interface IWoonobject {
  id?: number;
  straat: string;
  huisnummer: string;
  postcode: string;
  plaats: string;
  slug: string;
  vraagprijs: number;
  prijstype: PrijsType;
  kort?: string;
  beschrijving?: string;
  tags?: string;
  status: ObjectState;
  showOnAanbod: boolean;
  publishedOn?: Date;
  updated_at?: Date;
  images: IImageModel[];
}

export class WoonobjectDTO implements IWoonobject {
  id?: number;
  straat = "";
  huisnummer = "";
  postcode = "";
  plaats = "";
  slug = "";
  vraagprijs = -1;
  prijstype = PrijsType.KOSTEN_KOPER;
  kort?: string;
  beschrijving?: string;
  tags?: string;
  status: ObjectState = ObjectState.TEKOOP;
  showOnAanbod = true;
  publishedOn?: Date;
  updated_at?: Date;
  images: ImageModelDTO[] | ImageModel[] = [];
}

export default class Woonobject extends WoonobjectDTO {
  constructor(dto: WoonobjectDTO) {
    super();
    Object.assign(this, dto);
    for (let image of this.images) {
      image = new ImageModel(image as ImageModelDTO);
    }
  }

  getStatus(): string {
    switch (this.status) {
      case ObjectState.OVERLEG:
        return "In overleg";
      case ObjectState.TEKOOP:
        return "Te koop";
      case ObjectState.VERKOCHT:
        return "Verkocht";
      case ObjectState.VOORBEHOUD:
        return "Verkocht onder voorbehoud";
    }
  }
}

// export class VastgoedObject {
//   id!: number;
//   straat!: string;
//   huisnummer!: string;
//   postcode!: string;
//   plaats!: string;
//   slug!: string;
//   vraagprijs?: number;
//   prijstype?: PrijsType;
//   kort?: string;
//   beschrijving?: string;
//   tags?: string;
//   telaat!: boolean;
//   status!: ObjectState;
//   showOnAanbod!: boolean;
//   publishedOn?: Date;
//   updated_at?: Date;
//   images: ImageModel[];

//   constructor(object: IVastgoedObject) {
//     this.id = object.id;
//     this.straat = object.straat;
//     this.huisnummer = object.huisnummer;
//     this.postcode = object.postcode;
//     this.plaats = object.plaats;
//     this.slug = object.slug;
//     this.vraagprijs = object.vraagprijs;
//     this.prijstype = object.prijstype;
//     this.kort = object.kort ?? "";
//     this.beschrijving = object.beschrijving;
//     this.tags = object.tags;
//     this.telaat = object.telaat;
//     this.status = object.status;
//     this.showOnAanbod = object.showOnAanbod;
//     this.publishedOn = object.publishedOn;
//     this.updated_at = object.updated_at;
//     this.images = object.images.map((image) => new ImageModel(image));
//   }

//   get getStatus(): string {
//     switch (this.status) {
//       case ObjectState.OVERLEG:
//         return "In overleg";
//       case ObjectState.TEKOOP:
//         return "Te koop";
//       case ObjectState.VERKOCHT:
//         return "Verkocht";
//       case ObjectState.VOORBEHOUD:
//         return "Verkocht onder voorbehoud";
//     }
//   }
// }

// export interface VastgoedObjectGenerator {
//   straat: string;
//   huisnummer: string;
//   postcode: string;
//   plaats: string;
//   slug: string;
// }
