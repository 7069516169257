



























import { Component, Prop, Vue } from "vue-property-decorator";
import VastgoedObject from "@/models/Woonobject";
import { DataTableHeader } from "vuetify";
import DeleteItemFormDialog from "./DeleteItemFormDialog.vue";
import WoonobjectService from "@/services/WoonobjectService";

@Component({ components: { DeleteItemFormDialog } })
export default class ObjectList extends Vue {
  @Prop({ required: true }) private readonly value!: VastgoedObject[];
  @Prop({ required: true }) private readonly loading!: boolean;

  private tableHeaders: DataTableHeader[] = [
    { text: "straat", value: "straat" },
    { text: "huisnummer", value: "huisnummer" },
    { text: "plaats", value: "plaats" },
    { text: "slug", value: "slug" },
    { text: "status", value: "status" },
    { text: "acties", value: "id" },
  ];

  public async remove(e: string): Promise<void> {
    if (await WoonobjectService.delete(e)) {
      this.$toast.success("Object verwijderd!");
      (this.$refs.deleteDialog as DeleteItemFormDialog).close();
      this.value.splice(
        this.value.findIndex((object) => object.slug === e),
        1
      );
    }
  }
}
